<template>
    <div class="container">
      <h1>学习资源</h1>
      <div class="resource-grid">
        <div class="resource-item" v-for="resource in resources" :key="resource.title">
          <h3>{{ resource.title }}</h3>
          <p>{{ resource.description }}</p>
          <a :href="resource.link" target="_blank">访问资源</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        resources: [
          { title: 'MDN Web Docs', description: 'Web技术权威文档', link: 'https://developer.mozilla.org/' },
          { title: 'freeCodeCamp', description: '免费编程学习平台', link: 'https://www.freecodecamp.org/' },
          { title: 'GitHub', description: '开源代码托管平台', link: 'https://github.com/' },
          { title: 'Stack Overflow', description: '程序员问答社区', link: 'https://stackoverflow.com/' }
        ]
      }
    }
  }
  </script>
  
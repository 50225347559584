<template>
    <div class="container">
      <h1>关于我们</h1>
      <div class="about-section">
        <p>IT技术分享网站致力于提供高质量的技术内容，帮助开发者们不断学习和成长。我们的团队由经验丰富的IT专业人士组成，涵盖前端、后端、人工智能、大数据等多个领域。</p>
        <p>我们的使命是：</p>
        <ul>
          <li>分享最新的IT技术趋势和最佳实践</li>
          <li>提供清晰、易懂的技术教程和学习资源</li>
          <li>建立一个互助、共享的开发者社区</li>
        </ul>
        <p>如果您有任何问题、建议或合作意向，欢迎联系我们：contact@ittechshare.com</p>
      </div>
    </div>
  </template>
  
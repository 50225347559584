<template>
    <div>
      <section class="hero">
        <div class="container">
          <h1>探索IT技术的前沿</h1>
          <p>在这里，我们分享最新的IT技术趋势、编程技巧和行业洞察</p>
        </div>
      </section>
      <section class="container">
        <div class="tech-grid">
          <div class="tech-card" v-for="tech in techItems" :key="tech.title">
            <i :class="tech.icon"></i>
            <h3>{{ tech.title }}</h3>
            <p>{{ tech.description }}</p>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        techItems: [
          {
            icon: 'mdi mdi-language-javascript',
            title: '前端开发',
            description: '探索现代前端框架如Vue.js、React和Angular，以及最新的JavaScript特性。'
          },
          {
            icon: 'mdi mdi-server',
            title: '后端技术',
            description: '深入了解Node.js、Python、Java等后端技术，以及微服务架构和API设计。'
          },
          {
            icon: 'mdi mdi-database',
            title: '数据库和大数据',
            description: '学习SQL和NoSQL数据库，以及大数据处理技术如Hadoop和Spark。'
          },
          {
            icon: 'mdi mdi-cloud',
            title: '云计算和DevOps',
            description: '掌握AWS、Azure、Docker和Kubernetes等云计算和DevOps工具。'
          },
          {
            icon: 'mdi mdi-shield-check',
            title: '网络安全',
            description: '了解最新的网络安全威胁和防御策略，包括加密、认证和安全编码实践。'
          },
          {
            icon: 'mdi mdi-robot',
            title: '人工智能和机器学习',
            description: '探索AI和ML的前沿，包括深度学习、自然语言处理和计算机视觉。'
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  /* 样式代码与之前一致 */
  </style>
  
<template>
  <div id="app">
    <header>
      <nav class="container">
        <div class="logo">IT技术分享</div>
        <button class="menu-toggle" @click="toggleMenu">
          <i class="mdi mdi-menu"></i>
        </button>
        <ul class="nav-links" :class="{ active: menuActive }">
          <li v-for="item in menuItems" :key="item.text">
            <router-link :to="item.link" @click.native="closeMenu">{{ item.text }}</router-link>
          </li>
        </ul>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <p>&copy; 2023 IT技术分享网站 | 探索、学习、创新</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { text: '首页', link: '/' },
        { text: '技术博客', link: '/blog' },
        { text: '教程', link: '/tutorials' },
        { text: '资源', link: '/resources' },
        { text: '关于我们', link: '/about' }
      ],
      menuActive: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    closeMenu() {
      this.menuActive = false;
    }
  }
}
</script>

<style>
/* Add your styles here (from the original code) */
</style>

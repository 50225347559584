<template>
    <div class="container">
      <h1>技术教程</h1>
      <ul class="tutorial-list">
        <li v-for="tutorial in tutorials" :key="tutorial.title">
          <h3>{{ tutorial.title }}</h3>
          <p>{{ tutorial.description }}</p>
          <router-link :to="'/tutorials/' + tutorial.id">开始学习</router-link>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        tutorials: [
          { id: 1, title: 'Vue.js 从入门到精通', description: '全面掌握Vue.js框架，从基础概念到高级应用，step by step指导' },
          { id: 2, title: 'Python数据分析实战', description: '使用Python进行数据清洗、分析和可视化，掌握数据科学技能' },
          { id: 3, title: 'Docker容器化应用开发', description: '学习如何使用Docker构建、运行和部署应用，提高开发效率' }
        ]
      }
    }
  }
  </script>
  
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Blog from '../views/Blog.vue';
import Tutorials from '../views/Tutorials.vue';
import Resources from '../views/Resources.vue';
import About from '../views/About.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/blog', component: Blog },
  { path: '/tutorials', component: Tutorials },
  { path: '/resources', component: Resources },
  { path: '/about', component: About }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

<template>
    <div class="container">
      <h1>技术博客</h1>
      <div class="blog-post" v-for="post in blogPosts" :key="post.title">
        <h2>{{ post.title }}</h2>
        <p>{{ post.excerpt }}</p>
        <router-link :to="'/blog/' + post.id">阅读更多</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        blogPosts: [
          { id: 1, title: 'Vue 3 新特性详解', excerpt: 'Vue 3 带来了许多激动人心的新特性，本文将深入探讨Composition API、Teleport等重要更新...' },
          { id: 2, title: 'GraphQL vs REST API', excerpt: '在API设计中，GraphQL和REST各有优缺点。本文将比较两者的区别，并探讨何时使用哪种技术...' },
          { id: 3, title: '机器学习入门指南', excerpt: '本文将为您介绍机器学习的基本概念、常用算法，以及如何开始您的机器学习之旅...' }
        ]
      }
    }
  }
  </script>
  